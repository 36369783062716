<template>
    <dialog id="ColorVision-errorDialog" class="ColorVision-Dialog">
      <div class="header">
        测试结果
      </div>
      <div class="content">
        测试时间：{{ timeStr }} <br/>
        视力  左：{{ cyl_left }}   右：{{ cyl_right }} <br/>
        色盲测试：{{ color_vision }}<br/>
      </div>
    
      <div class="dialog-btn-warp">
          <button type="button" class="them-btn dialog-btn" @click="$emit('confirm', 0)">
            确定
          </button>
      </div>
    </dialog>
</template>
  
<script>
export default {
  name: 'ColorVisionDialog',
  props: {
    timeStr: String,
    cyl_left: String,
    cyl_right: String,
    color_vision: String
  },
  methods: {
    confirmdialog(value) {
      this.$emit('confirm', value);
    }
  }
}
</script>
  
<style lang="less" scoped>
  dialog{
    padding:0;
    border:none;
    box-shadow:0px 1px 3px rgba(0,0,0,0.2);
    width:90%;
    border-radius:10px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 999; 
  }
  
  .dialog-mod {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .dialog-mod .entryPic {
    flex: 1;
    align-self: flex-end;
    height: 90px;
    padding: 15px;
  }
  
  .header{
    padding:10px;
    color:#fff;
    border-radius:6px 6px 0 0;
    background:#e9933f;
    text-align: center;
  }
  .content{
    padding:20px;
    line-height: 2;
    font-size: 16px;
  }
  .tips{
    padding:20px;
    font-size: 12px;
  }
  .dialog-btn-warp {
    padding: 20px;
    
    .dialog-btn {
      padding: 0.15em;
      width: 100%; 
      font-size: 20px;
    }
  }
</style>