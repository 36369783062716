import { render, staticRenderFns } from "./colorVisionDialog.vue?vue&type=template&id=477da228&scoped=true&"
import script from "./colorVisionDialog.vue?vue&type=script&lang=js&"
export * from "./colorVisionDialog.vue?vue&type=script&lang=js&"
import style0 from "./colorVisionDialog.vue?vue&type=style&index=0&id=477da228&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "477da228",
  null
  
)

export default component.exports