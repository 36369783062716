import Vue from 'vue'
import Vuex from 'vuex'
import store_modular from '@/store/store_modular/index.js'
Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    store_modular
  },

  state: {
    colorVisionData: {
      timeStr: '',
      cyl_left: '',
      cyl_right: '',
      color_vision: ''
    }
  },
  mutations: {
    setColorVisionData(state, colorVisionData) {
      state.colorVisionData = colorVisionData;
    }
  },
  actions: {
    updateColorVisionData({ commit }, colorVisionData) {
      commit('setColorVisionData', colorVisionData);
    }
  },
  getters: {
    getColorVisionData: state => state.colorVisionData
  }
})
