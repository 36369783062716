
const router_modular = [
    {
        path: '/',
        name: 'index',
        meta: {
            title: '视力初筛'
        },
        component: () => import('@/views/index.vue')
    },
    {
      path: '/judgment',
      name: 'judgment',
      meta: { title: '问诊与自诉' },
      component:()=>import('@/views/judgment/index')
    },
    {
        path: '/userinfo',
        name: 'userinfo',
        meta: { title: '资料填写' },
        component:()=>import('@/views/info/userInfo')
    },
    {
        path: '/photoindex',
        name: 'photoindex',
        meta: { title: '视力初筛' },
        component:()=>import('@/views/photo/index')
    },
    {
        path: '/effect',
        name: 'effect',
        meta: { title: '视力初筛' },
        component:()=>import('@/views/info/effect')
    },
    {
        path: '/sightindex',
        name: 'sightindex',
        meta: { title: '在线验光：近视力测试' },
        component:()=>import('@/views/sight/index')
    },
    {
        path: '/operationPrompt',
        name: 'operationPrompt',
        meta: { title: '' },
        component:()=>import('@/views/sight/operationPrompt')
    },
    {
        path: '/eyeClosingCue',
        name: 'eyeClosingCue',
        meta: { title: '' },
        component:()=>import('@/views/sight/eyeClosingCue')
    },
    {
        path: '/eyeTest',
        name: 'eyeTest',
        meta: { title: '' },
        component:()=>import('@/views/sight/test')
    },
    {
        path: '/curEye',
        name: 'curEye',
        meta: { title: '' },
        component:()=>import('@/views/sight/cutEye')
    },
    {
        path: '/longsightindex',
        name: 'longsightindex',
        meta: { title: '视力初筛' },
        component:()=>import('@/views/longsight/index')
    },
    {
        path: '/longsightDistance',
        name: 'longsightDistance',
        meta: { title: '在线验光：远视力测试' },
        component:()=>import('@/views/longsight/distanceView')
    },
    {
        path: '/longsightoperationPrompt',
        name: 'longsightoperationPrompt',
        meta: { title: '在线验光：远视力测试' },
        component:()=>import('@/views/longsight/operationPrompt')
    },
    {
        path: '/longsightoperationPrompts',
        name: 'longsightoperationPrompts',
        meta: { title: '在线验光：远视力测试' },
        component:()=>import('@/views/longsight/operationPrompts')
    },
    {
        path: '/longsighteyeClosingCue',
        name: 'longsighteyeClosingCue',
        meta: { title: '在线验光：远视力测试' },
        component:()=>import('@/views/longsight/eyeClosingCue')
    },
    {
        path: '/longsighteyeTest',
        name: 'longsighteyeTest',
        meta: { title: '在线验光：远视力测试' },
        component:()=>import('@/views/longsight/test')
    },
    {
        path: '/longsightCurEye',
        name: 'longsightCurEye',
        meta: { title: '在线验光：远视力测试' },
        component:()=>import('@/views/longsight/cutEye')
    },
    {
        path: '/astigmatism',
        name: 'astigmatism',
        meta: { title: '在线验光：散光轴向测试' },
        component:()=>import('@/views/astigmatism/index')
    },
    {
        path: '/astigmatismTest',
        name: 'astigmatismTest',
        meta: { title: '在线验光：散光轴向测试' },
        component:()=>import('@/views/astigmatism/test')
    },
    {
        path: '/astigmatismProblem',
        name: 'astigmatismProblem',
        meta: { title: '在线验光：散光轴向测试' },
        component:()=>import('@/views/astigmatism/problem')
    },
    {
        path: '/astigmatismCurEye',
        name: 'astigmatismCurEye',
        meta: { title: '在线验光：散光轴向测试' },
        component:()=>import('@/views/astigmatism/cutEye')
    },
    {
        path: '/astigmatismQuanti',
        name: 'astigmatismQuanti',
        meta: { title: '在线验光：散光度数测试' },
        component:()=>import('@/views/astigmatismQuanti/index')
    },
    {
        path: '/astigmatismQuantiTest',
        name: 'astigmatismQuantiTest',
        meta: { title: '在线验光：散光度数测试' },
        component:()=>import('@/views/astigmatismQuanti/test')
    },
    {
        path: '/astigmatismQuantiColor',
        name: 'astigmatismQuantiColor',
        meta: { title: '在线验光：散光度数测试' },
        component:()=>import('@/views/astigmatismQuanti/color')
    },
    {
        path: '/astigmatismQuantiColorTest',
        name: 'astigmatismQuantiColorTest',
        meta: { title: '在线验光：散光度数测试' },
        component:()=>import('@/views/astigmatismQuanti/colorTest')
    },
    {
        path: '/astigmatismQuantiCurEye',
        name: 'astigmatismQuantiCurEye',
        meta: { title: '在线验光：散光度数测试' },
        component:()=>import('@/views/astigmatismQuanti/cutEye')
    },

    {
        path: '/ametropia',
        name: 'ametropia',
        meta: { title: '' },
        component:()=>import('@/views/ametropia/index')
    },
    {
        path: '/ametropiaTest',
        name: 'ametropiaTest',
        meta: { title: '' },
        component:()=>import('@/views/ametropia/test')
    },
    {
        path: '/ametropiaProblem',
        name: 'ametropiaProblem',
        meta: { title: '' },
        component:()=>import('@/views/ametropia/problem')
    },
    {
        path: '/ametropiaCurEye',
        name: 'ametropiaCurEye',
        meta: { title: '' },
        component:()=>import('@/views/ametropia/cutEye')
    },
    {
        path: '/eyeview',
        name: 'eyeview',
        meta: { title: '在线验光：眼底检查' },
        component:()=>import('@/views/eyeview/index')
    },
    {
        path: '/eyeviewTest',
        name: 'eyeviewTest',
        meta: { title: '在线验光：眼底检查' },
        component:()=>import('@/views/eyeview/test')
    },
    {
        path: '/eyeviewProblem',
        name: 'eyeviewProblem',
        meta: { title: '在线验光：眼底检查' },
        component:()=>import('@/views/eyeview/problem')
    },
    {
        path: '/eyeviewCurEye',
        name: 'eyeviewCurEye',
        meta: { title: '在线验光：眼底检查' },
        component:()=>import('@/views/eyeview/cutEye')
    },
    {
        path: '/twotone',
        name: 'twotone',
        meta: { title: '在线验光：双色测试' },
        component:()=>import('@/views/twotone/index')
    },
    {
        path: '/twotoneTest',
        name: 'twotoneTest',
        meta: { title: '在线验光：双色测试' },
        component:()=>import('@/views/twotone/test')
    },
    {
        path: '/twotoneProblem',
        name: 'twotoneProblem',
        meta: { title: '在线验光：双色测试' },
        component:()=>import('@/views/twotone/problem')
    },
    {
        path: '/twotoneCurEye',
        name: 'twotoneCurEye',
        meta: { title: '在线验光：双色测试' },
        component:()=>import('@/views/twotone/cutEye')
    },
    {
        path: '/sensitive',
        name: 'sensitive',
        meta: { title: '在线验光：对比敏感度' },
        component:()=>import('@/views/sensitive/index')
    },
    {
        path: '/sensitiveTest',
        name: 'sensitiveTest',
        meta: { title: '在线验光：对比敏感度' },
        component:()=>import('@/views/sensitive/test')
    },
    {
        path: '/sensitiveCurEye',
        name: 'sensitiveCurEye',
        meta: { title: '在线验光：对比敏感度' },
        component:()=>import('@/views/sensitive/cutEye')
    },
    {
        path: '/testFinish',
        name: 'testFinish',
        meta: { title: '视力初筛' },
        component:()=>import('@/views/testFinish')
    },
    {
        path: '/userForm',
        name: 'userForm',
        meta: { title: '' },
        component:()=>import('@/views/info/userForm')
    },
    {
        path: '/systemsuggestion',
        name: 'systemsuggestion',
        meta: { title: '提示和建议' },
        component:()=>import('@/views/judgment/systemSuggestion')
    },
    {
        path: '/pay',
        name: 'pay',
        meta: { title: '' },
        component:()=>import('@/views/pay/index')
    },
    {
        path: '/paysucess',
        name: 'paysucess',
        meta: { title: '' },
        component:()=>import('@/views/pay/paysucess')
    },
    {
        path: '/colorvision',
        name: 'colorvision',
        meta: { title: '视力初筛' },
        component:()=>import('@/views/colorVision/index')
    },
    {
        path: '/colorvisionTest',
        name: 'colorvisionTest',
        meta: { title: '视力初筛' },
        component:()=>import('@/views/colorVision/test')
    },

    {
        path: '/questionnaire',
        name: 'questionnaire',
        meta: { title: '调查问卷' },
        component:()=>import('@/views/questionnaire/index')
    },
    {
        path: '/questionnaireThanks',
        name: 'questionnaireThanks',
        meta: { title: '调查问卷' },
        component:()=>import('@/views/questionnaire/thanks')
    },
]
export default router_modular